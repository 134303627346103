(function($){
  $(document).ready(
    function () {
      $(document).foundation();

      var listElem = $("#offCanvas > ul").children();

      // When page loads hide all of the smaller menus
      $.each(listElem, function (index, value) {
        $(value).removeClass("expanded");
        $(value).addClass("collapsed");

        // edit size of element
        $(value).css("font-size", "22px");
        $(value).css("font-weight", "700");
        $(value).css("cursor", "pointer");
        
        var firstChildElem = value.firstChild;
        if(firstChildElem.nodeName !== 'A'){
          var ulElem = $(value).find("ul");
          ulElem.hide();
        }
      });

      // adding onclick functionality
      var showChildren = true;
      $.each(listElem, function (index, value) {
        var firstChildElem = value.firstChild;
        if(firstChildElem.nodeName !== "A"){
          $(value).on("click",  function () {
            var ulElem = $(this).find("ul");
            if(showChildren){
              $(this).removeClass("collapsed");
              $(this).addClass("expanded");
              ulElem.show(250);
              showChildren = false;
            }
            else{
              $(this).removeClass("expanded");
              $(this).addClass("collapsed");
              ulElem.hide(250);
              showChildren = true;
            }
          });
        }
      });
  });
}(jQuery));
